import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Metas from 'components/metas';
import { Location } from '@reach/router';
import classnames from 'classnames';
import Img from 'gatsby-image/withIEPolyfill';
import formatDate from 'helpers/formatDate';
import removeBr from 'helpers/removeBr';
import Billboard from 'components/billboard';
import RichText from 'components/richText';
import SmartLink from 'components/smartLink';
import styles from './blogPost.module.scss';
import { ReactComponent as TwitterIcon } from 'images/icon-twitter-alt.svg';
import { ReactComponent as FacebookIcon } from 'images/icon-facebook-alt.svg';
import { ReactComponent as LinkedinIcon } from 'images/icon-linkedin.svg';
import { ReactComponent as LeftCaretIcon } from 'images/icon-caret-left.svg';
import FeaturedContent from '../components/featuredContent';

//recives a string and returns a rich text format
const parseToRichTextFormat = (value, nodeType) => ({
  json: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: value,
            nodeType: 'text',
          },
        ],
        nodeType: nodeType,
      },
    ],
    nodeType: 'document',
  },
});

const findIndexToPlaceAd = (values) => {
  const middle = Math.floor(values.length / 2);

  for (let index = middle - 1; index < values.length - 1; index++) {
    const currentElement = values[index];
    const currentType = currentElement.nodeType;

    if (middle && currentType.startsWith('heading')) {
      const previousElement = values[index - 1]?.nodeType;

      if (
        previousElement &&
        (!previousElement.startsWith('heading') ||
          !previousElement.startsWith('hr'))
      ) {
        return index;
      }
    }

    if (!currentType.startsWith('heading')) {
      const nextElement = values[index + 1]?.nodeType;

      if (nextElement && nextElement.startsWith('heading')) {
        return index + 1;
      }
    }
  }

  return values.length;
};

export default function BlogPost({ data }) {
  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      }),
    []
  );

  //Current BlogPost data
  const post = data.contentfulBlogPost;
  const formattedDate = formatDate(post.publishDate);
  const metaTitle = post.metaTitle || post.title;

  /*
   *   Open share window
   */
  function sharePost(href, name) {
    window.open(
      href,
      name,
      'left=20,top=20,width=600,height=300,toolbar=0,resizable=1'
    );
    return false;
  }

  function Share() {
    return (
      <Location>
        {({ location }) => {
          const linkedInShare = `https://www.linkedin.com/shareArticle?mini=true&url=${
            location.href
          }&title=${post.metaTitle || post.title}&summary=${
            post.body.json.content.filter(
              (entry) => entry.nodeType === 'paragraph'
            )[0].content[0].value
          }&source=Newsela`;

          return !post?.slug
            ?.toLowerCase?.()
            .includes?.('note-from-the-ceo' && 'a-note-from-our-ceo') ? (
            <div className={styles.social}>
              <button
                onClick={() =>
                  sharePost(
                    `https://twitter.com/intent/tweet?text=${location.href}`,
                    'Share on Twitter'
                  )
                }
                className={styles.social__link}
              >
                <TwitterIcon />
              </button>
              <button
                onClick={() =>
                  sharePost(
                    `https://www.facebook.com/sharer/sharer.php?u=${location.href}`,
                    'Share on Facebook'
                  )
                }
                className={styles.social__link}
              >
                <FacebookIcon />
              </button>
              <button
                onClick={() => sharePost(linkedInShare, 'Share on LinkedIn')}
                className={styles.social__link}
              >
                <LinkedinIcon />
              </button>
            </div>
          ) : null;
        }}
      </Location>
    );
  }

  const footerText = parseToRichTextFormat(
    'Ready to engage, support, and grow every learner?',
    'paragraph'
  );

  const values = post.body.json.content;
  if (post.advertisement && values.length > 0) {
    const valueToInsert = {
      nodeType: 'advertisement-card',
      data: post.advertisement,
      content: [],
    };

    const insertIndex = findIndexToPlaceAd(values);
    values.splice(insertIndex, 0, valueToInsert);
  }

  const content = { ...post.body.json, content: values };

  function FeaturedBlogPosts() {
    //Featured BlogPosts data from MultiPurposeContainer field
    const featuredBlogPosts = post.featuredBlogPosts;

    //Default Values
    const defaultTitle = 'If you liked this article...';
    const defaultDescription = parseToRichTextFormat(
      'Browse more great content from Newsela.',
      'paragraph'
    );

    //Recent BlogPosts data from GraphQL query
    const defaultRecentBlogPosts = data.allContentfulBlogPost.nodes?.map(
      (post) => {
        return {
          id: post.id,
          title: post.metaTitle,
          label: post.blogCategory?.title,
          image: null,
          description:
            post.metaDescription?.metaDescription &&
            parseToRichTextFormat(
              post.metaDescription.metaDescription,
              'paragraph'
            ),
          buttonText: 'Read more',
          buttonUrl: `https://newsela.com/about/blog/${post.slug}`,
        };
      }
    );
    //Build recommendedBlogPostsModule object fields
    const recommendedBlogPostsModule = {
      title: featuredBlogPosts?.title || defaultTitle,
      description: featuredBlogPosts?.description || defaultDescription,
      contentBlocks: featuredBlogPosts?.contentBlocks || defaultRecentBlogPosts,
    };

    return <FeaturedContent {...recommendedBlogPostsModule} />;
  }

  return (
    <div className={styles.blogPostContainer}>
      <Metas
        title={`${metaTitle}`}
        description={
          post.metaDescription ? post.metaDescription.metaDescription : null
        }
        shareImage={post.shareImage ? post.shareImage.file.url : null}
        noindex={post.noindex}
      />
      <article
        className={classnames(
          styles.container,
          styles[post.blogCategory?.colorTheme]
        )}
      >
        <header className={styles.header}>
          <SmartLink to="/blog" className={styles.back_button}>
            <LeftCaretIcon /> Back
          </SmartLink>
          <div className={styles.category}>
            {removeBr(post.blogCategory?.title)}
          </div>
          <h1 className={styles.title}>{post.title}</h1>

          <Share />
          <div className={styles.metas}>
            <div className={styles.metas__author}>
              {post.author ? post.author.name : null}
              {post.authorTwo ? post.authorTwo.name : null}
            </div>
            <div className={styles.metas__date}>{formattedDate}</div>
          </div>
        </header>

        <div className={styles.image}>
          {post.heroImage && (
            <Img
              fit="contain"
              fluid={post.heroImage.fluid}
              alt={post.heroImage.title}
            />
          )}
        </div>

        <div className={styles.main}>
          <div className={styles.body}>
            <RichText content={content} />
            {post.footnotes && (
              <div className={styles.footnotes}>
                <RichText content={post.footnotes.json} />
              </div>
            )}
            {post.buttonText && (
              <div className={styles.blogPost_button_container}>
                <SmartLink
                  to={post.buttonUrl}
                  className={styles.blogPost_button}
                >
                  {post.buttonText}
                </SmartLink>
              </div>
            )}
            <Share />
          </div>

          <aside className={styles.sidebar}>
            <div className={styles.author}>
              <div
                className={styles.author__image}
                style={{
                  backgroundImage: `url(${post.author?.photo?.file?.url}?w=120)`,
                }}
              />
              <h3 className={styles.author__name}>{post.author?.name}</h3>
              <h3 className={styles.author__name}>{post.authorTwo?.name}</h3>
              <div className={styles.author__bio}>{post.author?.bio?.bio}</div>
              <div className={styles.author__bio}>
                {post.authorTwo?.bio?.bio}
              </div>
            </div>
          </aside>
        </div>
      </article>
      <FeaturedBlogPosts />
      <Billboard
        title="Inspire the desire to learn."
        subtitle={footerText}
        buttonText="Contact us"
        buttonUrl="https://go.newsela.com/contact"
        backgroundColor="Blue"
      />
    </div>
  );
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    allContentfulBlogPost(
      sort: { fields: publishDate, order: DESC }
      filter: { id: { ne: $id } }
      limit: 3
    ) {
      nodes {
        id
        slug
        publishDate
        blogCategory {
          title
        }
        metaTitle
        metaDescription {
          metaDescription
        }
      }
    }
    contentfulBlogPost(id: { eq: $id }) {
      featuredBlogPosts {
        ... on ContentfulMultipurposeContainer {
          ...MultipurposeContainerFragment
        }
      }
      buttonText
      buttonUrl
      banner {
        announcementText {
          json
        }
        show
        backgroundColor
      }
      title
      slug
      publishDate
      blogCategory {
        title
        colorTheme
      }
      advertisement {
        id
        title
        label
        image {
          title
          fluid(maxWidth: 700) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          file {
            url
          }
        }
        description {
          json
        }
        buttonText
        buttonUrl
      }
      author {
        name
        bio {
          bio
        }
        photo {
          file {
            url
          }
        }
      }
      body {
        json
        content {
          content {
            value
          }
        }
      }
      footnotes {
        json
      }
      heroImage {
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
        fluid(maxWidth: 635) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      shareImage {
        file {
          url
        }
      }
    }
  }
`;
